import { Children, useMemo, useCallback, useEffect, MouseEventHandler } from 'react';
import classNames from 'classnames';
import 'keen-slider/keen-slider.min.css';

import sliderClasses from 'styles/exports/slider.module.scss';
import config from 'config';
import createStyleVariables from 'utils/createStyleVariables';
import { useSlider } from 'hooks/useSlider';
import useBoolean from 'hooks/useBoolean';

import Dots from './components/Dots';
import Arrow from './components/Arrow';
import classes from './Slider.module.scss';
import Props from './Slider.types';

const Slider = ({
  className,
  slideClassName,
  classes: customClasses,
  children,
  dots,
  leftArrow,
  rightArrow,
  autoPlay = false,
  autoMove = false,
  duration = config.defaultBannerOptionAnimationTime,
  autoMoveDuration = config.defaultAutoMoveAnimationTime,
  showOverflow = false,
  slidesPerView = 1,
  slidesToMove = 1,
  timeout = config.defaultBannerOptionRefreshTime,
  withArrows = false,
  withDots = false,
  withIndependentArrows = false,
  lazyLoad = false,
  loop = false,
  vertical = false,
  width = 'auto',
  renderMode = 'precision',
  initialLoaded = [],
  selectedSlideIndex,
  onSlideChange = () => {},
}: Props): JSX.Element => {
  const [isInitialized, { on: turnOnIsInitialized }] = useBoolean(false);

  const childrenCount = Children.count(children);

  const { slider, sliderRef, currentSlide, loaded, isPaused, pause, resume, reload } = useSlider({
    autoPlay,
    autoMove,
    lazyLoad,
    loop,
    vertical,
    duration,
    autoMoveDuration,
    slidesPerView,
    renderMode,
    slideClass: classes.slide,
    timeout,
    selectedSlideIndex,
    slidesCount: childrenCount,
    initialLoaded,
  });

  const relativeSlidesToMove = Math.min(slidesToMove, childrenCount - currentSlide);

  const onLeftArrowClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      if (typeof slider.current?.moveToIdx === 'function') {
        slider.current?.moveToIdx(currentSlide - relativeSlidesToMove);
        reload();
      }
    },
    [slider, reload, currentSlide, relativeSlidesToMove]
  );

  const onRightArrowClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      if (typeof slider.current?.moveToIdx === 'function') {
        slider.current?.moveToIdx(currentSlide + relativeSlidesToMove);
        reload();
      }
    },
    [slider, reload, currentSlide, relativeSlidesToMove]
  );

  const onDotClick = useCallback(
    (index: number) => {
      if (typeof slider.current?.moveToIdx === 'function') {
        slider.current?.moveToIdx(index);
        reload();
      }
    },
    [slider, reload]
  );

  useEffect(() => {
    if (typeof selectedSlideIndex === 'number') {
      onDotClick(selectedSlideIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlideIndex]);

  useEffect(() => {
    onSlideChange(currentSlide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  const leftArrowComponent = useMemo(
    () =>
      typeof leftArrow === 'function'
        ? leftArrow({
            disabled: currentSlide === 0,
            onClick: onLeftArrowClick,
          })
        : leftArrow,
    [leftArrow, currentSlide, onLeftArrowClick]
  );

  const rightArrowComponent = useMemo(
    () =>
      typeof rightArrow === 'function'
        ? rightArrow({
            disabled: currentSlide === childrenCount - slidesPerView,
            onClick: onRightArrowClick,
          })
        : rightArrow,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rightArrow, currentSlide, childrenCount, slidesPerView, onRightArrowClick]
  );

  const arrows = useMemo(
    () =>
      childrenCount > slidesPerView ? (
        <>
          {leftArrowComponent || <Arrow variant="left" disabled={currentSlide === 0} onClick={onLeftArrowClick} />}
          {rightArrowComponent || (
            <Arrow
              variant="right"
              disabled={currentSlide === childrenCount - slidesPerView}
              onClick={onRightArrowClick}
            />
          )}
        </>
      ) : (
        <></>
      ),
    [
      leftArrowComponent,
      rightArrowComponent,
      currentSlide,
      slidesPerView,
      childrenCount,
      onLeftArrowClick,
      onRightArrowClick,
    ]
  );

  const dotsComponent = useMemo(
    () =>
      typeof dots === 'function'
        ? dots({
            currentSlide,
            isPaused,
            onClick: onDotClick,
          })
        : dots,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dots, isPaused, currentSlide, onDotClick]
  );

  useEffect(() => {
    const currentSlider = slider.current;
    const onMouseOver = () => {
      pause();
    };
    const onMouseOut = () => {
      resume();
    };

    currentSlider?.container?.addEventListener('mouseenter', onMouseOver);
    currentSlider?.container?.addEventListener('mouseleave', onMouseOut);

    return () => {
      currentSlider?.container?.removeEventListener('mouseenter', onMouseOver);
      currentSlider?.container?.removeEventListener('mouseleave', onMouseOut);
    };
  }, [slider, pause, resume]);

  useEffect(() => {
    if (slider && !isInitialized) {
      if (typeof slider.current?.update === 'function') {
        slider.current?.update();
      }
      turnOnIsInitialized();
    }
  }, [slider, isInitialized]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        style={createStyleVariables({ sliderWidth: `${width}px` })}
        className={classNames(classes.sliderWrapper, customClasses?.root, className)}
      >
        <div
          ref={sliderRef}
          className={classNames(sliderClasses.slider, classes.slider, {
            [classes.showOverflow]: showOverflow,
          })}
        >
          {Children.map(children, (child, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={classNames(classes.slide, customClasses?.slide, slideClassName)}>
              {!lazyLoad || loaded[index] ? child : null}
            </div>
          ))}
        </div>
        {withArrows && !withIndependentArrows && arrows}
      </div>
      {!withArrows && withIndependentArrows && arrows}
      {withDots &&
        (dotsComponent || (
          <div className={classNames(classes.dots, customClasses?.dots)}>
            <Dots count={childrenCount} currentSlide={currentSlide} onDotClick={onDotClick} />
          </div>
        ))}
    </>
  );
};

export default Slider;
