import { useState, useEffect, useRef, useCallback, MutableRefObject } from 'react';
import { useKeenSlider, KeenSliderInstance, KeenSliderOptions } from 'keen-slider/react';

type Props = {
  slideClass: string;
  autoPlay: boolean;
  autoMove: boolean;
  lazyLoad: boolean;
  loop: boolean;
  vertical: boolean;
  duration: number;
  autoMoveDuration: number;
  slidesPerView: number;
  slidesCount: number;
  timeout: number;
  selectedSlideIndex?: number;
  initialLoaded: boolean[];
  renderMode: KeenSliderOptions['renderMode'];
};

type HookResponse = {
  slider: MutableRefObject<KeenSliderInstance | null>;
  sliderRef: (node: HTMLElement | null) => void;
  isPaused: boolean;
  currentSlide: number;
  loaded: boolean[];
  pause: () => void;
  resume: () => void;
  reload: () => void;
};

export const useSlider = ({
  slideClass,
  autoPlay,
  autoMove,
  lazyLoad,
  loop,
  vertical,
  duration,
  autoMoveDuration,
  slidesPerView,
  slidesCount,
  timeout,
  selectedSlideIndex,
  renderMode,
  initialLoaded,
}: Props): HookResponse => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const interval = useRef<any>(null);
  const [shouldReload, setShouldReload] = useState(true);
  const [refreshTimeout, setRefreshTimeout] = useState(timeout);
  const [isPaused, setIsPaused] = useState(false);
  const [lastCall, setLastCall] = useState(new Date().getTime());
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(selectedSlideIndex || 0);
  const [loaded, setLoaded] = useState<boolean[]>(initialLoaded);

  const animation = { duration: autoMoveDuration * slidesCount, easing: (t: number) => t };
  const autoMoveProps: KeenSliderOptions = {
    renderMode: 'precision',
    drag: false,
    created(s) {
      s.moveToIdx(slidesCount - 1, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + slidesCount - 1, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + slidesCount - 1, true, animation);
    },
  };

  const reload = useCallback(() => {
    if (!isPaused) {
      setShouldReload(true);
    }
  }, [isPaused]);

  const pause = useCallback(() => {
    setIsPaused(true);
    setShouldReload(true);
  }, []);

  const resume = useCallback(() => {
    setLastCall(new Date().getTime());
    setIsPaused(false);
    setShouldReload(true);
  }, []);

  const [sliderRef, slider] = useKeenSlider({
    selector: `.${slideClass}`,
    renderMode,
    initial: selectedSlideIndex || 0,
    loop: autoPlay || autoMove || loop,
    defaultAnimation: { duration },
    vertical,
    slides: {
      origin: 'auto',
      perView: slidesPerView,
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel);
      if (autoPlay) {
        reload();
      }
    },
    dragStarted() {
      if (autoPlay) {
        reload();
      }
    },
    ...(autoMove ? autoMoveProps : {}),
  });

  useEffect(() => {
    if (autoPlay && isPaused) {
      const deltaTime = new Date().getTime() - lastCall;
      const newTimeElapsed = timeElapsed + deltaTime;
      setTimeElapsed(newTimeElapsed);
      setRefreshTimeout(timeout - newTimeElapsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaused]);

  useEffect(() => {
    if (autoPlay && shouldReload && slider) {
      if (interval.current) {
        clearInterval(interval.current);
      }

      if (!isPaused) {
        interval.current = setInterval(() => {
          if (autoPlay && slider.current) {
            slider.current.next();
          }
          setLastCall(new Date().getTime());
          setTimeElapsed(0);
          setRefreshTimeout(timeout);
          reload();
        }, refreshTimeout);
      }
      setShouldReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slider, sliderRef, shouldReload, isPaused]);

  useEffect(
    () => () => {
      if (interval) {
        clearInterval(interval.current);
      }
    },
    []
  );

  useEffect(() => {
    if (lazyLoad) {
      const newLoaded: boolean[] = [...loaded];
      [...new Array(slidesPerView)].forEach((_, i) => {
        newLoaded[currentSlide + i] = true;
      });
      setLoaded(newLoaded);
    }
  }, [lazyLoad, currentSlide]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    slider,
    sliderRef,
    isPaused,
    currentSlide,
    loaded,
    pause,
    resume,
    reload,
  };
};
